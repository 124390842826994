import React, { ReactElement } from 'react';
import Img from 'gatsby-image';
import { Asset } from 'contentful';
import { isNil, pathOr } from 'ramda';

export interface ImageProps {
	image: Asset;
	type?: 'mobile' | 'desktop';
}

const Image: React.FC<ImageProps> = ({ image, type }): ReactElement => {
	const fluid = pathOr('', ['fluid'], image);

	const getType = (): string => isNil(type) ? 'none' : type;

	// TODO: add atributes (alt, desc,...)
	return <Img className={`a-image a-image__${getType()}`} fluid={fluid} />;
};

export default Image;
