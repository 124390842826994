import React, { ReactElement } from 'react';
import { Asset } from 'contentful';
import { isNil, pathOr } from 'ramda';
import isImage from 'is-image';
import cx from 'classnames';
import Div100vh from 'react-div-100vh';
import scrollTo from 'gatsby-plugin-smoothscroll';

import Scroll from '../../assets/images/svg/scroll.svg';

import Video from '../Video/Video';
import Image from '../Image/Image';

// TODO: check types
export interface ProjectCoverAssetProps {
	desktop: Asset;
	mobile: Asset;
	fullscreen?: boolean;
	scroll?: boolean;
}

const renderAsset = (data: Asset, type: 'mobile' | 'desktop'): ReactElement | null => {
	const url = pathOr(null, ['file', 'url'], data);

	if (isNil(url)) {
		return null;
	}

	return isImage(url) ? <Image image={data} type={type} /> : <Video video={data} type={type} />;
};

const renderScroll = (): ReactElement => (
	<button
		className='m-project-cover__scroll'
		onClick={() => scrollTo('#projectContent')}
		title='Scroll to content'
	>
		<Scroll />
	</button>
);

const render = (data: ProjectCoverAssetProps): ReactElement => (
	<div className={cx('m-project-cover', data.fullscreen && 'm-project-cover--fullscreen')}>
		{!isNil(data.desktop) && renderAsset(data.desktop, 'desktop')}
		{!isNil(data.mobile) && renderAsset(data.mobile, 'mobile')}
		{data.scroll && renderScroll()}
	</div>
);

const ProjectCoverAsset: React.FC<ProjectCoverAssetProps> = ({ desktop, mobile, fullscreen = false, scroll = false}): ReactElement => (
	!fullscreen ? render({ desktop, mobile, fullscreen, scroll }) : <Div100vh>{render({ desktop, mobile, fullscreen, scroll })}</Div100vh>
);

export default ProjectCoverAsset;
