import React, { ReactElement, useEffect, useRef } from 'react';
import { Asset } from 'contentful';
import { isNil, pathOr } from 'ramda';

export interface VideoProps {
	video: Asset;
	type?: 'mobile' | 'desktop';
}

const Video: React.FC<VideoProps> = ({ video, type }): ReactElement => {
	const videoEl = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (videoEl && videoEl.current) {
			videoEl.current.play();
			videoEl.current.loop = true;
		}
	}, [videoEl]);

	const src = pathOr('', ['file', 'url'], video);
	const title = pathOr('', ['file', 'fileName'], video);

	const getType = (): string => isNil(type) ? 'none' : type;

	return <video ref={videoEl} className={`a-video a-video__${getType()}`} src={src} title={title} loop muted autoPlay playsInline></video>;
};

export default Video;
